(function ($) {
    'use strict';

    $(function () {
        
        $('#search_all').on({
            click: function () {
                if ($('#menupanel-search').css("display") == "none") {
                    $('#menupanel-search').css('display', 'block');
                    $('.mobile-toggle-cta').addClass('collapsed');
                    $('#main_nav').removeClass('show');
                    document.body.style.position = 'fixed';
                    document.body.style.top = `-${window.scrollY}px`;
                    document.body.style.position = '';
                    document.body.style.top = '';
                } else {
                    $('#menupanel-search').css('display', 'none');

                    const scrollY = document.body.style.top;
                    document.body.style.position = '';
                    document.body.style.top = '';
                    window.scrollTo(0, parseInt(scrollY || '0') * -1);
                }
            }
        });
        $('#navbar_searchform').on({
            submit: function () {
                    if ($('#menupanel-search').css("display") == "none") {
                        $('#menupanel-search').css('display', 'block');

                        document.body.style.position = 'fixed';
                        document.body.style.top = `-${window.scrollY}px`;
                        document.body.style.position = '';
                        document.body.style.top = '';
                    } else {
                        $('#menupanel-search').css('display', 'none');

                        const scrollY = document.body.style.top;
                        document.body.style.position = '';
                        document.body.style.top = '';
                        window.scrollTo(0, parseInt(scrollY || '0') * -1);
                    }
                    
                //}
            }
        });
        $('#closesearch').on({
            click: function () {
                $('#menupanel-search').css('display', 'none');
            }
        });
        
    });

}(jQuery));