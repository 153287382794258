document.addEventListener("DOMContentLoaded", function () {
  const $dropdown = $(".dropdown");
  const $dropdownToggle = $(".dropdown-toggle");
  const $dropdownMenu = $(".dropdown-menu");
  const showClass = "show";

  const $logo = $(".navbar-brand img");
  const $mainNav = $("#main_nav");
  const $navbar = $(".navbar");

  $(window).on("load resize scroll", function () {
    if (this.matchMedia("(min-width: 768px)").matches) {
      // Dropdown
      $dropdown.hover(
        function () {
          const $this = $(this);
          $this.addClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "true");
          $this.find($dropdownMenu).addClass(showClass);
        },
        function () {
          const $this = $(this);
          $this.removeClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "false");
          $this.find($dropdownMenu).removeClass(showClass);
        }
      );
    } else {
      $dropdown.off("mouseenter mouseleave");
    }
    if (this.matchMedia("(min-width: 1200px)").matches) {
      // Logo animation
      if ($(window).scrollTop() > 80) {
        $logo.addClass("-scrolled");
        $mainNav.addClass("-reduced");
        $navbar.addClass("-appears");
      } else {
        $logo.removeClass("-scrolled");
        $mainNav.removeClass("-reduced");
        $navbar.removeClass("-appears");
      }
    }
  });

  // Suppress page scroll when mobile nav opened
  $(".mobile-toggle-cta").click(function (e) {
    var menuItem = $(this);
    if (menuItem.attr("aria-expanded") === "true") {
      $("body").css("overflow", "hidden");
    } else if (menuItem.attr("aria-expanded") === "false") {
      $("body").css("overflow", "auto");
    }
  });
});
