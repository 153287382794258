// Gallery image hover
$(".img-wrapper").hover(
  function () {
    $(this).find(".img-overlay").animate({ opacity: 1 }, 200);
  },
  function () {
    $(this).find(".img-overlay").animate({ opacity: 0 }, 200);
  }
);

// Lightbox
var $overlay = $('<div id="overlay"></div>');
var $image = $("<img>");
var $prevButton = $(
  '<div id="prevButton"><i class="fa fa-chevron-left"></i></div>'
);
var $nextButton = $(
  '<div id="nextButton"><i class="fa fa-chevron-right"></i></div>'
);
var $exitButton = $('<div id="exitButton"><i class="fa fa-times"></i></div>');

// Add overlay
$overlay
  .append($image)
  .prepend($prevButton)
  .append($nextButton)
  .append($exitButton);
$("#gallery").append($overlay);

// Hide overlay by default
$overlay.hide();

let allImages = [];
let currentIndex = 0;

// When an image is clicked
$(".img-overlay").click(function (event) {
  // Prevent default behavior
  event.preventDefault();

  // Get the href attribute of the clicked image's link
  var imageLocation = $(this).prev().attr("href");

  // Retrieve all images with the same href attribute
  allImages = $("#image-gallery a")
    .map(function () {
      return $(this).attr("href");
    })
    .get();

  // Find the index of the clicked image in allImages array
  currentIndex = allImages.indexOf(imageLocation);

  // Set the clicked image src to the overlay image
  $image.attr("src", allImages[currentIndex]);

  // Show the overlay
  $overlay.fadeIn("fast");
});

// Navigate to the next image
$nextButton.click(function (event) {
  event.stopPropagation(); // Prevent overlay from closing

  // Increment index (loop back to start if at the end)
  currentIndex = (currentIndex + 1) % allImages.length;

  // Update overlay image source
  $image.hide().attr("src", allImages[currentIndex]).fadeIn(200);
});

// Navigate to the previous image
$prevButton.click(function (event) {
  event.stopPropagation(); // Prevent overlay from closing

  // Decrement index (loop back to end if at the start)
  currentIndex = (currentIndex - 1 + allImages.length) % allImages.length;

  // Update overlay image source
  $image.hide().attr("src", allImages[currentIndex]).fadeIn(200);
});

// Close overlay on exit button click
$exitButton.click(function () {
  $overlay.fadeOut("fast");
});

// Close overlay when clicking outside the image
$overlay.click(function (event) {
  if (event.target === this) {
    $overlay.fadeOut("fast");
  }
});
